/*
*
* Animate.css
* --------------------------------------------------
*/


.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  opacity: 1;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

html:not(.lt-ie10) .not-animated {
  opacity: 0;
}

/**
* FadeIn Keyframes Animation
*/

@include keyframes(fadeIn) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

/**
* FadeInUp Keyframes Animation
*/

@include keyframes(fadeInUp) {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/**
* FadeInDown Keyframes Animation
*/

@include keyframes(fadeInDown) {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

/**
* FadeInLeft Keyframes Animation
*/

@include keyframes(fadeInLeft) {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

/**
* FadeInRight Keyframes Animation
*/

@include keyframes(fadeInRight) {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

/*
**
* FadeOut Keyframes Animation
*/

@include keyframes(fadeOut) {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

/**
* SlideInUp Keyframes Animation
*/
 
@include keyframes(slideInUp) {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

/**
* SlideInDown Keyframes Animation
*/

@include keyframes(slideInDown) {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

/**
* SlideInLeft Keyframes Animation
*/

@include keyframes(slideInLeft) {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

/**
* SlideInRight Keyframes Animation
*/

@include keyframes(slideInRight) {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

/**
* SlideOutDown Keyframes Animation
*/

@include keyframes(slideOutDown) {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}


/**
* FadeInUpSmall Keyframes Animation
*/

.fadeInUpSmall {
  animation-name: fadeInUpSmall;
  transform-origin: 50% 100%;
}

@keyframes fadeInUpSmall {
  0% {
    opacity: 0;
    transform: translateY(60px) scale(0.9);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}


/**
* FadeInLeftSmall Keyframes Animation
*/

.fadeInLeftSmall {
  animation-timing-function: ease-in-out;
  -webkit-animation-name: fadeInLeftSmall;
  animation-name: fadeInLeftSmall;
}

@keyframes fadeInLeftSmall {
  0% {
    opacity: 0;
    transform: translateX(-100px) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

/**
* FadeInRightSmall Keyframes Animation
*/

.fadeInRightSmall {
  animation-timing-function: ease-in-out;
  -webkit-animation-name: fadeInRightSmall;
  animation-name: fadeInRightSmall;
}

@keyframes fadeInRightSmall {
  0% {
    opacity: 0;
    transform: translateX(100px) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}