// Custom button variants
@mixin btn-variant($clr, $bg, $bd, $clr-hover, $bg-hover, $bd-hover) {
  color: $clr;
  background-color: $bg;
  border-color: $bd;

  &:hover,
  &:focus,
  &:focus:active,
  &:active,
  &:hover {
    color: $clr-hover;
    background-color: $bg-hover;
    border-color: $bd-hover;
  }
}

// Button sizes
@mixin btn-size($padding-vertical, $padding-horizontal, $font-size, $line-height) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
}

// Reset Button
@mixin reset-button() {
  background: none;
  border: none;
  display: inline-block;
  padding: 0;
  outline: none;
  outline-offset: 0;
  cursor: pointer;
  -webkit-appearance: none;

  &::-moz-focus-inner {
    border: none;
    padding: 0;
  }
}