/*
 * CSS Styles that are needed by jScrollPane for it to operate correctly.
 *
 * Include this stylesheet in your site or copy and paste the styles below into your stylesheet - jScrollPane
 * may not operate correctly without them.
 */
$scroll-width: 5px;
$scroll-background: lighten($primary, 35%);
$dragPanel-background: rgba($primary, .5);

.jspContainer {
	overflow: hidden;
	position: relative;
}

.jspPane {
	position: absolute;
	right: $scroll-width;
}

.jspVerticalBar {
	position: absolute;
	top: 0;
	right: 0;
	width: $scroll-width;
	height: 100%;
	background: $scroll-background;
	pointer-events: auto;
}

.jspHorizontalBar {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 16px;
	background: $scroll-background;
	pointer-events: auto;
}

.jspCap {
	display: none;
}

.jspHorizontalBar .jspCap {
	float: left;
}

.jspTrack {
	background: $scroll-background;
	position: relative;
}

.jspDrag {
	background: $dragPanel-background;
	width: 8px;
	position: relative;
	top: 0;
	left: 0;
	cursor: pointer;
}

.jspHorizontalBar .jspTrack,
.jspHorizontalBar .jspDrag {
	float: left;
	height: 100%;
}

/*
* Arrow
*/
.jspArrow {
	background: #50506d;
	text-indent: -20000px;
	display: block;
	cursor: pointer;
	padding: 0;
	margin: 0;
}

.jspArrow.jspDisabled {
	cursor: default;
	background: #80808d;
}

.jspVerticalBar .jspArrow {
	height: 16px;
}

.jspHorizontalBar .jspArrow {
	width: 16px;
	float: left;
	height: 100%;
}

.jspVerticalBar .jspArrow:focus {
	outline: none;
}

.jspCorner {
	background: #eeeef4;
	float: left;
	height: 100%;
}
