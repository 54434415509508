//
// Comments
// --------------------------------------------------

// Comment minimal
.comment-minimal {
  max-width: 325px;
  > * + * {
    margin-top: 5px;
  }
}

.comment-minimal__meta {
  font-size: 14px;
  line-height: 1.4;
  margin-left: -9px;
  margin-right: -9px;
  color: $primary;

  > li {
    position: relative;
    display: inline-block;
    padding-left: 9px;
    padding-right: 9px;
  } 

  a {
    &:hover {
      color: $black;
    }
  }

  > li:not(:last-child) {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 3px;
      height: 0;
      border-bottom: 1px solid;
    }
  }
}

.comment-minimal__link {
  font-size: 14px;
  font-weight: 500;
  line-height: (22 / 14);
  color: $black;

  a {
    display: inline;
    color: inherit;

    &:hover {
      color: $primary;
    }
  }
}

// Box comment post
.box-comment {
  * + & { margin-top: 32px; }
  & + & {
    margin-top: 40px;
  }
  * + time,
  p + * { margin-top: 8px; }
  * + p { margin-top: 0; }

  p {
    font-size: 14px;
    line-height: 24px;
  }

  time,
  a {
    font-size: 12px;
    font-style: italic;
  }

  time { color: $black; }
  img { width: auto; }
  .unit-body {
    width: 100%;
  }
}

.box-comment.box-comment-reply {
  padding-left: 20px;

  * + & {
    margin-top: 45px;
  }

  @include media-breakpoint-up(sm) {
    padding-left: 40px;
    * + & {
      margin-top: 20px;
    }
  }
  @include media-breakpoint-up(md) {
    padding-left: 100px;
  }
}

.box-comment__icon {
  color: $gray-1;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid;

  .icon { font-size: 27px; }

  @include media-breakpoint-up(md) {
  }
}

.box-comment__body {
  position: relative;
  background-color: $gray-lighter;
  padding: 15px;

  &:before {
    bottom: 100%;
    left: 35px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: $gray-lighter;
    border-width: 11px;
    margin-left: -11px;
  }

  @include media-breakpoint-up(sm) {
    &:before {
      right: 100%;
      top: 35px;
      bottom: auto;
      left: auto;
      border-right-color: $gray-lighter;
      border-bottom-color: transparent;
      margin-top: -11px;
      margin-left: 0;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 30px 35px;
  }
}

// product comment

.box-comment__product {
  position: relative;
  .unit-body {
    width: 100%;
  }
  .rating-list {

    .icon {
      font-size: 12px;
    }

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 25px;
      right: 30px;
    }
  }
}