/*
*
* Fonts
* ==================================================
*/

@import "custom-styles/fonts/font-awesome";  
@import "custom-styles/fonts/linearicons"; 
@import "custom-styles/fonts/material-icons";

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/roboto/roboto-v29-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/roboto/roboto-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/roboto/roboto-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/roboto/roboto-v29-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/roboto/roboto-v29-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/roboto/roboto-v29-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/montserrat/montserrat-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/montserrat/montserrat-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/montserrat/montserrat-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/montserrat/montserrat-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/montserrat/montserrat-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/montserrat/montserrat-v18-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
